import {
  get, flattenDeep, map, filter, uniqBy, uniq, intersectionBy,
} from 'lodash';
import i18n from '../i18n';
import tenantConfig from '../config/tenantConfig.json';
import { getValidEpochDate, getDateDisplayFormat } from './dateTimeUtils';

export const uniqueObjects = (jsonObj, key) => {
  let getObject = [];
  if (key) {
    getObject = [
      ...new Map(
        jsonObj.map((item) => item && ([item?.[key], item])),
      ).values(),
    ].map((value) => ({
      value: `${value?.[key]}`,
      label: value?.[key],
      [key]: value?.[key],
      id: `${value?.[key]}_${value.id}`,
      isActive: value?.isActive,
    }));
  } else {
    getObject = jsonObj.map((element) => {
      const newarray = {
        ...element,
        label: `${element.id} ${element.name}`,
        value: element.id,
        id: element.id,
        isActive: element?.isActive,
      };
      return newarray;
    });
  }
  return getObject;
};

const randomValue = () => Math.random().toString(36).substr(2);
export const token = () => `${randomValue() + randomValue()}-${tenantConfig.tenantId}`;

export const storeFilterJson = () => {
  const filterInputName = [
    { name: 'owner', label: 'Select Franchise', serviceKey: 'owner' },
    {
      name: `${get(tenantConfig, 'basic.allowedCityName[0]', '')}`,
      label: `Select ${get(tenantConfig, 'basic.allowedCityName[0]')}`,
      serviceKey: 'city',
    },
    { name: 'priceTier', label: 'Select Price Tier', serviceKey: 'pricingTier' },
    { name: 'storeName', label: 'Select Restaurant', serviceKey: 'storeName' },
  ];
  return filterInputName;
};
export const CustomStoreFilterJsonClone = (
  availableFields = ['storeName'],
) => storeFilterJson().filter((each) => availableFields.includes(each.name));

export const CustomStoreFilterJson = (
  availableFields = ['owner', 'storeName'],
) => storeFilterJson().filter((each) => availableFields.includes(each.name));

export const CustomStoreCityFilterJson = (
  availableFields = ['owner', 'city', 'priceTier', 'storeName'],
) => storeFilterJson().filter((each) => availableFields.includes(each.name));

export const getAllprivateandActive = (data) => data.filter((rec) => rec.isPrivate && rec.isActive);

export const getFilterPrivatePromotion = (data, needData) => intersectionBy(map(getAllprivateandActive(data), 'code'), needData);

export const getSelectOptionsFormat = (value, t, type = 'common.paymentTypes') => (value && value.length
  ? value.map((each, index) => ({
    label: t ? t(`${type}.${each}`) : each,
    value: each,
    id: `${each}_${index}`,
  }))
  : []);

export const getChanelWiseServices = (array, key, key2) => {
  const chanelservice = array ? array.map((each, index) => ({
    label: each?.[key],
    value: each?.[key],
    id: `${each?.[key]}_${index}`,
    allowedServices: key2 ? [...each?.[key2]] : [],
  })) : [];
  return chanelservice;
};

export const getChannelWiseStores = (val, stores) => {
  const optionMap = { storeName: [], services: [] };
  return val.length && stores ? stores?.reduce((result, current) => {
    const resultClone = { ...result };
    const currentStoreChannels = map(current.channelWiseServices, 'channel');
    if (currentStoreChannels.includes(val[0].value)) {
      const currentStoreServices = uniq(flattenDeep(map(current.channelWiseServices, 'services')));
      if (current.isActive === true) {
        resultClone.storeName = [...resultClone.storeName, current];
        resultClone.services = uniq([...resultClone.services, ...currentStoreServices]);
      }
    }
    return resultClone;
  }, optionMap) : optionMap;
};

export const getServiceWiseStores = (val, stores) => (
  val.length && stores ? stores.reduce((result, current) => {
    const resultClone = [...result];
    const currentStoreServices = uniq(flattenDeep(map(current.channelWiseServices, 'services')));
    return currentStoreServices.includes(val[0].value)
      ? uniq([...resultClone, current])
      : resultClone;
  }, []) : []
);

// const groupBy = (objectArray, property, days, hours) => chain(objectArray)
//   .groupBy(property)
//   .map((value, key) => ({ [days]: key, [hours]: value }))
//   .value();

export const getHoursDaygObject = (hoursArray, paymentArray) => {
  const defaultOperationHrs = {};
  if (paymentArray) {
    paymentArray.forEach((payment) => {
      hoursArray.forEach((each) => {
        Object.keys(each.availableHours).forEach((keys) => {
          const startTime = `${payment}_${each.dayOfWeek}_${keys}`;
          defaultOperationHrs[startTime] = get(each, `availableHours.${keys}`, '');
        });
      });
    });
  } else {
    hoursArray.forEach((each) => {
      Object.keys(each.availableHours).forEach((keys) => {
        const startTime = `${each.dayOfWeek}_${keys}`;
        defaultOperationHrs[startTime] = get(each, `availableHours.${keys}`, '');
      });
    });
  }
  return {
    ...defaultOperationHrs,
  };
};

export const getPaymentMapHoursObject = (paymentOptionArray) => {
  const paymentMappingHours = [];
  if (paymentOptionArray) {
    paymentOptionArray.forEach((option) => {
      Object.values(option?.operatingHours).forEach((each) => {
        Object.keys(each?.availableHours).forEach((keys) => {
          const dat = `${option?.name}_${each?.dayOfWeek}_${keys}`;
          paymentMappingHours[dat] = get(each, `availableHours.${keys}`);
        });
      });
    });
  }
  return {
    ...paymentMappingHours,
  };
};

export const getPaymentMapOptions = (paymentOptionArray) => {
  const options = [];
  if (paymentOptionArray) {
    paymentOptionArray.forEach((option) => {
      options.push(option.name);
    });
  }
  return [...options];
};

export const formatPaymentMappingData = (data, isTenderMapping = false) => {
  const keys = Object.keys(data);
  const { defaultPaymentMethod } = data;

  let formattedDataForPost = [];
  let setDefaultMethod = false;
  keys.map((key) => {
    if (key !== 'defaultPaymentMethod') {
      if (key === defaultPaymentMethod) {
        setDefaultMethod = true;
      }
      if (isTenderMapping) {
        formattedDataForPost = [
          ...formattedDataForPost,
          {
            name: key,
            isDefault: setDefaultMethod,
            operatingHours: [
              {
                dayOfWeek: 'EVERYDAY',
                availableHours: { startTime: '0000', endTime: '2359' },
              },
            ],
          },
        ];
      } else {
        formattedDataForPost = [
          ...formattedDataForPost,
          {
            name: key,
            isDefault: setDefaultMethod,

            operatingHours: {
              weekDayAvailibilities: [
                {
                  dayOfWeek: 'EVERYDAY',
                  availableHours: { startTime: '0000', endTime: '2359' },
                },
              ],
            },
          },
        ];
      }
    }
    setDefaultMethod = false;
    return true;
  });
  return [...formattedDataForPost];
};

export const getDefaultValue = (data) => {
  let selectedDefault = '';

  data.forEach((each) => {
    if (each.isDefault === true) {
      selectedDefault = each.name;
    }
  });
  return selectedDefault;
};

export const addRestaurantDetailsToData = (data, storeData) => {
  const formattedData = [];

  data?.forEach((each) => {
    formattedData.push({
      ...storeData,
      ...each,
    });
  });
  return formattedData;
};

export const generateStoresTableData = (stores) => {
  let index = 0;
  const dataTableData = stores.map((store) => store.channelWiseServices.map(
    (channelObj) => channelObj.services.map((val) => ({
      id: (index += 1),
      restaurant: store.label,
      channel: channelObj.channel,
      service: val,
    })),
  ));

  return flattenDeep(dataTableData);
};
export const getItemExclusionPayload = (
  payload,
) => {
  const previousValue = [];
  payload.forEach((element) => {
    previousValue.push({
      storeId: element?.storeId,
      channel: element?.channel,
      service: element?.service,
      activityId: element?.activityId,
      itemId: element?.itemId,
      reason: element?.reason,
      entityType: element?.entityType || '',
    });
  });
  return previousValue;
};

export const getPayloadStoreInfo = (data, isbulk) => data && {
  storeId: isbulk ? map(data[0], 'id') : map(data[0], 'id')[0],
  channel: isbulk ? map(data[1], 'label') : map(data[1], 'label')[0],
  service: isbulk ? map(data[2], 'label') : map(data[2], 'label')[0],
};

export const isStatusCodeSuccess = (status) => status && /^20/g.test(status);

export const getLangSpecificValue = (val, key) => (val ? filter(val, { lang: i18n.language })[0]?.[key || 'value'] : '');
export const getLangSpecificValueAddHope = (val, key) => (filter(val, { lang: i18n.language })[0]?.[key || 'value'] === 'Add Hope' ? 'Add Hope Donation Amount' : '');
export const getLangSpecificTableValues = (val, selectorName, key = '') => (val ? `${selectorName}[${val.findIndex((option) => option.lang === i18n.language)}].${key || 'value'}` : '');
export const getLangSpecificlist = (val, key) => (val ? [{
  lang: i18n.language,
  [key || 'value']: val,
}] : []);

export const getAllStoreSpecificCities = (stores, address, city) => (uniqBy(
  getSelectOptionsFormat(
    stores?.map((item) => get(getLangSpecificValue(item.localAddress, address), city)),
  ),
  'value',
));

export const formatMenuItemsData = (menuItems) => menuItems?.map((item) => ({
  id: item.id,
  value: item.id,
  mdmId: item.mdmId,
  name: item.name,
  // eslint-disable-next-line
  label: item.id + '-' + getLangSpecificValue(item.dname),
}));

export const formatAllProductsData = (menuItems) => menuItems?.map((item) => ({
  id: item.id,
  value: item.id,
  name: item.name,
  url: item.url,
  // eslint-disable-next-line
  label: item.id + '-' + getLangSpecificValue(item.dname),
}));

export const formatAllCategoriesData = (categories) => categories?.map((item) => ({
  id: item.id,
  value: item.id,
  name: item.name,
  url: item.url,
  // eslint-disable-next-line
  label: item.id + '-' + getLangSpecificValue(item.dname),
}));

export const formatChannelServiceWiseMenuItems = (categories) => categories?.map((item) => ({
  ...item,
  id: item.id,
  value: item.id,
  name: item.name,
  url: item.url,
  // eslint-disable-next-line
  label: item.id + '-' + getLangSpecificValue(item.dname),
}));

export const formatImageUploadData = (data) => data?.map((item) => ({
  id: item.id,
  value: item.id,
  name: item.name,
  url: item.url,
  langName: getLangSpecificValue(item.dname),
  // eslint-disable-next-line
  label: getLangSpecificValue(item.dname),
}));

export const formatAllCategoriesIamgeUpload = (categories) => categories?.map((item) => ({
  id: item.id,
  value: item.id,
  name: item.name,
  // eslint-disable-next-line
  langName: item.id + '-' + getLangSpecificValue(item.dname),
  // eslint-disable-next-line
  label:  item.id + '-' + getLangSpecificValue(item.dname),
  url: item.id, // item.url,
}));

export const formatMenuIamgeUpload = (menuItems) => menuItems?.map((item) => ({
  id: item.id,
  value: item.id,
  mdmId: item.mdmId,
  name: item.name,
  // eslint-disable-next-line
  langName: item.id + '-' + getLangSpecificValue(item.dname),
  // eslint-disable-next-line
  label: item.id + '-' + getLangSpecificValue(item.dname),
  url: item.id, // item.url,
}));

export const deepFilter = (
  data, str, dateKeyNames, booleanValueNames, filterHiddenKeys, dateFormat,
) => (data && !!Object.keys(data).find((key) => {
  if (filterHiddenKeys && filterHiddenKeys.length && filterHiddenKeys.includes(key)) {
    return false;
  }

  if (data?.[key] instanceof Array) {
    return data?.[key].find((subItem) => deepFilter(subItem, str));
  }
  if (typeof data?.[key] === 'number' && dateKeyNames && dateKeyNames.length && dateKeyNames.includes(key)) {
    const formattedDate = getDateDisplayFormat(getValidEpochDate(data?.[key]), dateFormat);
    return formattedDate.toLowerCase().includes(str.toLowerCase());
  }

  if (typeof data?.[key] === 'boolean' && booleanValueNames && booleanValueNames.size && booleanValueNames.find((val, prop) => data?.[key] === prop && val.toLowerCase().includes(str.toLowerCase()))) {
    return true;
  }

  if (typeof data?.[key] !== 'string' && typeof data?.[key] !== 'number') {
    return deepFilter(data?.[key], str);
  }

  return (typeof data?.[key] === 'string' || typeof data?.[key] === 'number') && data[key].toString().toLowerCase().includes(str.toLowerCase());
}));

export const getArraywithKeyValuePair = (
  selectedList,
  key,
  value,
  selectedItem,
) => filter(selectedList, [key, value], (e) => e[selectedItem]);

export const isActiveStores = (storeArray, keyName = null) => {
  const sotresFilter = filter(storeArray, (o) => o.isActive);
  if (!keyName) {
    return sotresFilter;
  }
  return getChannelWiseStores([{ value: keyName }], sotresFilter)?.storeName;
};

export const getPriceBasedOnCurrency = (val, currencyType, isDisplay) => {
  // if (!val) return false;
  let price = val;
  switch (currencyType) {
    case 'Rupee':
      price = isDisplay ? Number(val) / 100 : Number(val) * 100;
      break;
    case 'AUD':
      price = isDisplay ? Number(val) / 100 : Number(val) * 100;
      break;
    case 'ZAR':
      price = isDisplay ? Number(val) / 100 : parseInt(Number(val) * 100, 10);
      break;
    default:
      price = val;
      break;
  }
  return price;
};

export const getUrlParamsMap = (queryStr) => {
  const params = (queryStr?.replace('?', ''))?.split(/&(?=[^\s&=]+=)/g);
  return params && new Map(params?.map((item) => item.split('=')));
};

export const convertPriceToRs = (price) => {
  const getprice = parseFloat(price / tenantConfig.basic.priceConveter, 10).toFixed(2);
  return getprice;
};

export const getCurrentUnit = (val, unitType, isDisplay) => {
  if (!val) return false;

  let unit = val;
  switch (unitType) {
    case 'Kms':
      unit = isDisplay ? Number(val) / 1000 : Number(val) * 1000;
      break;
    default:
      unit = val;
      break;
  }

  return unit;
};
export const getSelectStorGroupFormat = (value) => (value && value.length
  ? value.map((each) => ({
    label: each.storeGroupName,
    value: each.id,
    id: each.id,
  }))
  : []);

export const filterStoresFromGroup = (data, restaurantGroupList) => {
  const val = map(data, 'value');
  let st = '';
  const groupstore = [];

  val.forEach((num) => {
    st = (filter(restaurantGroupList, { id: num }))[0]?.stores;

    groupstore.push(st);
  });

  return (uniqBy(flattenDeep(groupstore)?.map(({ id }) => id)));
};

export const storeSelectedFromGroups = (data, restaurantList) => data.map((num) => {
  let st = (filter(restaurantList, { id: num }))[0];
  st = { id: st?.id, label: st?.label, value: st?.id };

  return st;
});

export const nameConcatination = (firstname, lastname) => `${firstname} ${lastname}`;
